/**
 * Enumerationen für die Datenklassen
 */

/**
 * Daten-Kategorie im Schnellsuche Suchergebnis
 */
export enum SchnellsucheKategorieEnum {
    Kontakte,
    Adressen,
    Listen,
    Gruppen
}

/**
 * Typ eines Elementes im Schnellsuche-Ergebnis
 */
export enum SchnellsucheErgebnisElementTypEnum {
    NatuerlichePerson,
    Unternehmen,
    Adresse,
    Gruppe,
    Liste
}

/**
 * Kontakttyp in der Detailsuche
 */
export enum KontaktSuchTyp {
    Alle,
    NatuerlichePerson,
    Unternehmen
}

/**
 * Statusfilter
 */
export enum Status {
    None,
    Ungueltig,
    Gueltig
}

/**
 * Art des Benutzers
 */
export enum BenutzerTyp {
    Normal = 0,
    Extern = 1,
    Administrator = 2
}

/**
 * Action Button Values
 */
export enum ActionButtonEnum {
    // Primärer Button
    Primary = 1,

    // Sekundärer Button
    Secondary = 2,

    // Tertiärer Button
    Tertiary = 3
}

/**
 * Enumerationen für die Zentrale Wahl
 */

/**
 * Wahltypen
 */
export enum Wahltyp {
    EU,
    Bund,
    Land,
    Gemeinde,
    Buergermeister
}

/**
 * Unterschriften für die Wahlkarte
 */
export enum WahlkarteUnterschriftEnum {
    Buergermeister = 0,
    Wahlleiter = 1,
    Sachbearbeiter = 2
}

/**
 * Enum mit den Werten des Wahlstatus
 */
export enum WahlstatusEnum {
    /** Wahl befindet sich in Vorbereitung */
    Vorbereitung = 0,

    /** Anlage einer Wahl */
    Anlage = 10,

    /** Zentraler Upload des eWählerverzeichnis */
    WahlbestandUpload = 12, // 15

    /**
     * Gesamtimport des eWählerverzeichnisses wurde durchgeführt.
     * Diesen Status gibt es nur bei der ZENTRALEN Wahl
     */
    WahlbestandGesamtimport = 14, // 31

    /**
     * Gesamtimport des eWählerverzeichnisses wurde zu den einzelnen Wahlen ausgespielt.
     * Diesen Status gibt es nur bei der ZENTRALEN Wahl
     */
    GesamtwahlbestandAusgespielt = 16, // 32

    /** eWählerverzeichnis automatisch importieren */
    WahlbestandImportAutomatisch = 20,

    /** eWählerverzeichnis manuell importieren */
    WahlbestandImportManuell = 30,

    /** Wahlbestand wurde erstellt */
    WahlbestandErstellt = 40,

    /** Adressen und Sprengel des Wahlbestands wurden geprüft */
    WahlbestandGeprueft = 50,

    /** Wahlbestand zu Bearbeitung freigegeben */
    WahlbestandFreigegeben = 60,

    /** Kontakt- und Wahllokaldetails kontrollieren */
    KontaktWahllokaldatenKontrolliert = 70,

    /** Verknüpfung der wahlberechtigten Personen mit der zentralen Person durchgeführt */
    DatenverknuepfungDurchgefuehrt = 80,

    /**
     *  Abgleich der wahlberechtigten Personen mit der zentralen Person durchgeführt
     * Wert im Mai 23 hinzugeführt
     */
    DatenabgleichDurchgefuehrt = 83,

    /** Freigabe der Wahlinformationen für den zentralen Druck */
    WahlinformationDruckfreigabe = 90,

    /**
     * ASV wurde importiert. Wird durchgeführt um die am vorgezogenen
     * Wahltag abgegebenen Stimmen zu übernehmen
     */
    ASVImportiert = 100,

    /** Wahl abgeschlossen */
    Wahlabgeschlossen = 120,

    /** Wahl historisch */
    WahlHistorisch = 150
}

/**
 * Fortschritt im Wahlstatus
 */
export enum WahlstatusStepStateEnum {
    NotStarted = 0,
    InProgress = 1,
    Failure = 2,
    Completed = 3
}
