// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UiConstants } from '@core/constants/ui-constants';
import { AppConfig } from '@core/services/config-asset-loader.service';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

/**
 * Konfiguration für die Authentifikation am Identity-Server
 * Die clientId der Konfiguration muss mit dem Namen des Clients am Identity-Server übereinstimmen
 * Die angegebenen Scopes müssen mit der Client-Konfiguration am Identity-Server übereinstimmen
 * Es ist nicht notwendig alle in der Client-Konfiguration enthaltenen Scopes aufzuführen
 */
const authConfig: AuthConfig = {
    issuer: '', // Wird über eine Setter-Funktion gesetzt
    clientId: 'k5-management-ui',
    responseType: 'code',
    redirectUri: window.location.origin + '/',
    postLogoutRedirectUri: window.location.origin + '/',
    scope: 'openid profile email k5:name k5-civis-api-mandanten k5-civis-api-personen k5-civis-api-wahlvorbereitung k5-civis-api-adressen k5-civis-api-signalr k5-civis-api-kontaktmanagement k5-civis-api-notifications',
    showDebugInformation: false,
    skipIssuerCheck: true,
    oidc: true,
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: 10000, // For faster testing
    timeoutFactor: 0.75, // For faster testing
    sessionChecksEnabled: false,
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
    silentRefreshShowIFrame: false
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    // Gibt an, ob gerade der Logout Vorgang läuft
    private loggingOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loggingOut$: Observable<boolean> = this.loggingOut.asObservable();

    private isAuthenticatedSubject = new BehaviorSubject<boolean>(this.oauthService.hasValidAccessToken());
    public isAuthenticated$ = this.isAuthenticatedSubject.asObservable().pipe(distinctUntilChanged());

    private hasAuthenticationFailedSubject = new BehaviorSubject<boolean>(false);
    public hasAuthenticationFailed$ = this.hasAuthenticationFailedSubject.asObservable().pipe(distinctUntilChanged());

    private config: AppConfig = null;

    /**
     * Konstruktor
     * @param oauthService OAuthService für die Authentifizierung (inkludiert Middleware)
     * @param router Für die Navigation
     */
    constructor(private oauthService: OAuthService, private router: Router) {
        // Session Storage als Speicher für die Token verwenden
        this.oauthService.setStorage(sessionStorage);

        // Subscribe to authentication events
        this.registerSubscriptions();
    }

    /**
     * Setzt nach der Initialisierung der Application die Konfiguration mit den URL der Services
     * sowie Parameter, Konfiguration und Silent Refresh im OAuthService für die Anmeldung am Idetity Provider.
     * @param config AppConfig
     */
    setConfiguration(config: AppConfig): void {
        this.config = config;

        // Setzen der Discovery URL des Identit Providers
        authConfig.issuer = config.k5Identity.k5IdentityDiscoveryUrl;

        // Angelegte Konfiguration verwenden
        this.oauthService.configure(authConfig);

        this.oauthService.setupAutomaticSilentRefresh();
    }

    /**
     * Setzt Subscriptions auf OAuthEvents, welche während der Verwendung der Bibliothek
     * verarbeitet werden. Dient vorangig zur Eingrenzung von Fehlern auf der Dev- und Test-Stage.
     */
    private registerSubscriptions(): void {
        // Ausgabe der Events während der Verwendung des OAuthService
        if (this.config?.env?.name === 'dev' || 'test') {
            this.oauthService.events.subscribe((event) => {
                if (event instanceof OAuthErrorEvent) {
                    console.error('OAuthErrorEvent Object:', event);
                    let parameters: any = event.params ? event.params : undefined;
                    if (
                        ['code_error'].includes(event.type) &&
                        parameters &&
                        ['login_required'].includes(parameters.error)
                    ) {
                        console.log('Error with login required!');
                        // Erneuter redirect zum Login beim Identity Provider
                        sessionStorage.clear();
                        this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());
                        this.login();
                    }
                } else {
                    console.warn('OAuthEvent Object:', event);
                }
            });
        }

        // Listener auf den Storage um Änderungen des access_token aus einem anderen Browser-Tab zu registrieren
        // Eine Änderung des access_tokens während der Anmeldung kann zu ungewolltem Verhalten führen
        // Siehe: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
        // Wird derselbe User in einem anderen Tab ausgeloggt, erfolgt der Logout des User in allen anderen
        // offenen Tabs während des silent_refresh, da dieser einen Fehler verursacht.
        window.addEventListener('storage', (event) => {
            // key ist 'null' wenn das Event durch '.clear()' ausgelöst wurde
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }
            if (this.config?.env?.name === 'dev' || 'test') {
                console.warn('Änderung am access_token festgestellt (vermutlich durch einen anderen Browser-Tab).');
            }
            if (event.key === 'auth_data_updated') {
                if (this.config?.env?.name === 'dev' || 'test') {
                    console.warn('Änderung nach Logout handeln.');
                }
                sessionStorage.removeItem('auth_data_updated');
            }

            this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());

            // Nach einem Event prüfen, ob das AccessToken noch gültig ist
            if (!this.oauthService.hasValidAccessToken()) {
                sessionStorage.clear();
                // Andernfalls Login durchführen
                this.login();
            }
        });

        // Wenn der Token erhalten wurde
        this.oauthService.events
            .pipe(filter((e) => ['token_received'].includes(e.type)))
            .subscribe(() => this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken()));

        // Nach dem Logout über Storage event andere Tabs benachrichtigen
        this.oauthService.events.pipe(filter((e) => ['logout'].includes(e.type))).subscribe((e) => {
            sessionStorage.setItem('auth_data_updated', 'logout');
            sessionStorage.removeItem('auth_data_updated');
            this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());
        });

        // Ausloggen des Benutzers im Fehlerfall
        this.oauthService.events
            .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
            .subscribe(() => {
                // Bei Fehler, und ungültigem AccessToken
                if (!this.oauthService.hasValidAccessToken()) {
                    sessionStorage.clear();
                    // Login durchführen
                    this.login();
                } else {
                    // Andernfalls logout durchführen, da Abmeldung an anderer Stelle furchgeführt wurde
                    this.logout();
                }
                this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());
            });

        // Führt der User am Identity Provider eienen Logout durch müssen diese Events
        // verarbeitet werden um den Fehler '#4839 Menü verschwindet' zu lösen
        // Beim nächsten Silent Refresh muss sich der User neu anmelden
        this.oauthService.events.subscribe((event) => {
            if (event instanceof OAuthErrorEvent) {
                this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());

                // Ein ausloggen über den IdentityProvider führt zu folgenden Events:
                if (event.type === 'code_error' || 'silent_refresh_error') {
                    // code_error Eventparameter behandeln
                    if (event.params) {
                        // Cast auf any, da event.params vom Typ 'object' ist
                        // und kein Zugriff auf Properties möglich ist
                        const parameters: any = event.params;
                        if (parameters.error === 'login_required') {
                            this.login();
                        }
                    }
                    // silent_refresh_error Eventparameter behandeln
                    if (event.reason) {
                        // Cast auf any, da event.reason vom Typ 'object' ist
                        // und kein Zugriff auf Properties möglich ist
                        const reason: any = event.reason;
                        if (reason.type === 'code_error' && reason.params && reason.params.error === 'login_required') {
                            this.login();
                        }
                    }
                }
            }
        });
    }

    /**
     * Login Sequenz starten
     */
    public async runInitialLoginSequence(): Promise<void> {
        this.hasAuthenticationFailedSubject.next(false);

        // Daten des hash anzeigen
        if (location.hash) {
            console.log('Hash-Fragment gefunden, Darstellung als Tabelle...');
            console.table(
                location.hash
                    .substring(1)
                    .split('&')
                    .map((kvp) => kvp.split('='))
            );
        }

        try {
            // Konfiguration laden
            await this.oauthService.loadDiscoveryDocument();

            // Login durchführen
            await this.oauthService.tryLogin();

            // Login erfolgreich, Benutzer angemeldet
            if (this.oauthService.hasValidAccessToken()) {
                return Promise.resolve();
            }

            try {
                // Versuchen den User mit einem Refresh Token erneut anzumelden
                // Damit entfällt die Eingabe von Benutzername und Passwort
                await this.oauthService.silentRefresh();

                // Silent refresh erfolgreich, Benutzer angemeldet
                return Promise.resolve();
            } catch (result) {
                // Silent refresh erfolglos
                // Behandelt den aufgetreten Fehler und startet den Login erneut
                // Nach dem erfolgreichen Login ist der Benutzer angemeldet
                this.handleSilentRefreshFailures(result);

                // Andernfalls kann hier das Promise rejected werden.
                // Ein rejected Promise führt zu einer kurzen Anzeige der Fehlermeldung vor dem
                // redirect und eigentlichen Login auf dem IdentityProvider.
            }
        } catch (error) {
            // Ausgabe der Fehlermeldungen damit weitere Events für das Fehlverhalten des AuthService
            // getracked werden können
            console.log('Fehler bei der Authentifizierung: ' + JSON.stringify(error));
            // catch OAuthErrorEvent
            // Siehe https://openid.net/specs/openid-connect-core-1_0.html#AuthError für eine Liste der
            // möglichen Events.

            // Fehler bei der Anmeldung, z.B.: Falsches Passwort wurde eingegeben, und anstelle eines erneuten Login
            // wird der Login abgebrochen -> redirect zum UI mit Fehler
            if (['code_error'].includes(error.type) && ['access_denied'].includes(error.params.error)) {
                // Erneuter redirect zum Lgon beim Identity Provider
                this.login();

                // Benutzer hat Bookmark bei Identity Login Seite gesetzt
                // Handhabt wenn der Benutzer von Identity redirected wird
            } else if (['invalid_nonce_in_state'].includes(error.type)) {
                // Falls wert gesetzt, Fehlermeldung anzeigen
                if (sessionStorage.getItem('retry_login')) {
                    this.hasAuthenticationFailedSubject.next(true);
                } else {
                    // Wert setzen
                    sessionStorage.setItem('retry_login', 'true');
                    // Login wiederholen
                    this.login();
                }
                // Fehlermeldung anzeigen
            } else {
                this.hasAuthenticationFailedSubject.next(true);
            }
        } finally {
            // Prüfen ob der zurückgegeben State am Identity Server keine strings 'undfined' oder 'null'
            // enthält und auf die URL des State redirecten.
            // 'undefined' oder 'null' können auftreten, falls in der Applikation
            // initCodeFlow(undefined | null) aufgerufen wird
            if (
                this.oauthService.state &&
                this.oauthService.state !== 'undefined' &&
                this.oauthService.state !== 'null'
            ) {
                let stateUrl = this.oauthService.state;
                if (stateUrl.startsWith('/') === false) {
                    stateUrl = decodeURIComponent(stateUrl);
                }
                if (this.config?.env?.name === 'dev' || 'test') {
                    console.log(
                        `Es wurde ein Zustand: ${decodeURIComponent(
                            this.oauthService.state
                        )} gefunden, Umleitung auf: ${stateUrl}`
                    );
                }
                this.router.navigateByUrl(stateUrl);
            }
        }
    }

    /**
     * Behandelt den aufgetretenen Fehler während des Silent refresh und startet je nach
     * Fehlercode den Login Prozess für den Benutzer neu.
     * @param result any -> OAuthErrorEvent
     */
    handleSilentRefreshFailures(result: any): void {
        console.log('Fehler beim Silent Refresh: ' + JSON.stringify(result));
        // catch OAuthErrorEvent
        // Siehe https://openid.net/specs/openid-connect-core-1_0.html#AuthError für eine Liste der
        // möglichen Events.
        // Es werden nur die Events geprüft, welche für die Implementierung und
        // Anfrage an den Identity Provider notwendig sind.
        const errorResponsesRequiringUserInteraction = [
            'interaction_required',
            'login_required',
            'account_selection_required',
            'consent_required',
            'access_denied'
        ];

        if (
            result &&
            result.reason &&
            result.reason.params &&
            errorResponsesRequiringUserInteraction.indexOf(result.reason.params.error) >= 0
        ) {
            // Wird ein oben gelistetet Event zurückgegeben wissen wir, dass eine
            // Anmeldung durch den User erforderlich ist -> redirect Anmeldung am Identity Provider
            console.warn('Benutzerinteraktion für den Login wird benötigt, manueller Login muss abgewartet werden.');
            this.login();
        }
    }

    /**
     * Umleitung zur Anmeldung am Identity Provider
     */
    public login() {
        this.oauthService.initLoginFlow();
    }

    /**
     * Logout und Variablen aus dem Session-Storage entfernen
     */
    logout(): void {
        sessionStorage.removeItem(UiConstants.UIINFO);
        sessionStorage.removeItem(UiConstants.UIRECHTE);
        this.loggingOut.next(true);
        this.oauthService.logOut();
    }

    /**
     * Liefert das AccesToken aus dem OAuthService zurück
     * @returns Bearer Token
     */
    getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    /**
     * Liefert true wenn ein gültiges Access-Token vorhanden ist
     */
    hasValidAccessToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    /**
     * Liefert true wenn ein gültiges ID- und Access-Token vorhanden sind
     */
    isAuthenticated(): boolean {
        return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
    }
}
